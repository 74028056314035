import * as React from 'react';

import Layout from '../../components/layout';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg max-w-prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  Our process
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  What does ERP do?
                </h1>
                <p>Coming sooooooon!</p>
                {/* <p className="pb-8 text-xl leading-8 text-gray-500">
                  For most projects our hourly rate is $120. A basic
                  installation needs around 160 hours work, costing about
                  $20,000.
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  You <span className="text-teal-600">might</span> need ERP if:
                </h1>
                <p className="text-xl leading-8 text-gray-500">
                  Even though Odoo costs far less to purchase and operate than
                  direct competitors like Netsuite and SAP Business One, moving
                  from your one software system to another is not a small
                  undertaking.
                </p>
                <p className="py-8 text-xl leading-8 text-gray-500">
                  To make the decision an easy one for you, we work hard at
                  every point to be clear and exact about what we plan to do,
                  how long it&apos;ll take, and how much we&apos;ll charge.
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  You <span className="text-teal-600">might not</span> need ERP
                  if:
                </h1>
                <p className="text-xl leading-8 text-gray-500">
                  Even though Odoo costs far less to purchase and operate than
                  direct competitors like Netsuite and SAP Business One, moving
                  from your one software system to another is not a small
                  undertaking.
                </p>
                <p className="py-8 text-xl leading-8 text-gray-500">
                  To make the decision an easy one for you, we work hard at
                  every point to be clear and exact about what we plan to do,
                  how long it&apos;ll take, and how much we&apos;ll charge.
                </p>
                <hr />
                <hr />
                <p className="py-8 text-xl leading-8 text-gray-500">
                  These are the steps we&apos;ll take to get you up and running
                  with Odoo.
                </p>  */}
              </div>
              {/* <div className="mx-auto prose prose-lg text-gray-500">
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z"
                      />
                      <path
                        d="M150.026,80.39h-22.84c-6.91,0-10.933,7.044-10.933,13.158c0,5.936,3.209,13.158,10.933,13.158
h7.259v85.36c0,8.734,6.257,13.605,13.176,13.605s13.185-4.881,13.185-13.605V92.771C160.798,85.789,156.945,80.39,150.026,80.39z"
                      />
                    </g>
                  </svg>
                  No-obligation analysis
                </h2>
                <p>
                  <ul>
                    <li>
                      We&apos;ll talk with your key users, the people from each
                      department who need to decide whether Odoo is suitable for
                      their area of responsibility.
                    </li>
                    <li>
                      We&apos;ll work with them to figure out how Odoo can be
                      used to get their jobs done.
                    </li>
                    <li>
                      We&apos;ll build a custom Odoo demo using sample data from
                      your business so your key users can do a hands-on
                      evaluation.
                    </li>
                  </ul>
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
